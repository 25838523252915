#weeklyTable {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse; /* Ensures table borders collapse into a single border */
    table-layout: auto; /* Allows the table to expand and adapt to the content width */
    overflow: auto;
    border: 1px solid #ddd;
}

    #weeklyTable th, #weeklyTable td {
        padding: 5px 10px; /* Adds consistent padding inside table cells */
        text-align: center;
        white-space: nowrap; /* Prevents text from wrapping within cells */
        vertical-align: middle; /* Ensures text is vertically centered */
    }

    /* Header Styles */
    #weeklyTable th {
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        background-color: #f5f5f5; /* Subtle background for header */
        cursor: pointer;
        border: none;
    }

        /* Sort Column Indicator */
        #weeklyTable th[style*="font-weight: bold"] {
            font-weight: bold; /* Highlight sorting column */
            background-color: #dcdcdc; /* Change background color for sorted columns */
        }

        /* Sticky First Column Styles */
        #weeklyTable td:first-child,
        #weeklyTable th:first-child {
            position: -webkit-sticky; /* Safari-specific sticky position */
            position: sticky;
            left: 0;
            padding-left: 15px; /* Ensure first column has enough padding */
            white-space: normal; /* Allow wrapping of long text */
            word-wrap: break-word; /* Ensure long names are wrapped in the sticky column */
        }

        /* Bold Name Column Only If It's The Sorted Column */
        #weeklyTable th.sticky-column-sorted,
        #weeklyTable td.sticky-column-sorted {
            font-weight: bold; /* Only make name bold if it is the sorted column */
        }

    /* Data Cell Styles */
    #weeklyTable td {
        padding-right: 5px;
        padding-left: 5px;
    }

        /* Conditional Coloring for Cells */
        #weeklyTable td.red {
            color: red;
            text-decoration: line-through;
        }

        #weeklyTable td.green {
            color: green;
            text-decoration: none;
        }

        #weeklyTable td.black {
            color: black;
        }

/* Table Container */
#weeklyTableDiv {
    position: relative;
    height: 100%;
    overflow: auto;
    max-width: 100%; /* Allow table to grow and shrink within its container */
}

/* Cell width adjustments */
#weeklyTable td, #weeklyTable th {
    width: auto;
    min-width: 50px; /* Sets minimum width to avoid overly small cells */
    word-wrap: break-word; /* Allow text wrapping in data cells */
    white-space: normal; /* Allow text to wrap */
}

/* Adjust for Mobile View (Responsive) */
@media screen and (max-width: 768px) {

        #weeklyTable th, #weeklyTable td {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }

    #allWeekNo {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Ensure proper scrolling behavior */
#weeklyTableDiv {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    margin-top: 20px;
}

/* Make sure that the table doesn't shrink columns too small */
#weeklyTable td, #weeklyTable th {
    min-width: 50px; /* Prevents table columns from becoming too narrow */
}

/* Add some space between columns */
#weeklyTable th,
#weeklyTable td {
    padding-left: 8px;
    padding-right: 8px;
}

/* Sticky column adjustments for left positioning and z-index */
.sticky-column {
    position: sticky;
    left: 0;
    background-color: #fff; /* Ensure sticky column has a white background */
    z-index: 2; /* Make sure it stays above the rest of the table */
}

/* Styling for the header container (week selector) */
#allWeekNo {
    position: relative;
    z-index: 10; /* Ensures the week picker stays above the table */
    margin-bottom: 10px;
    width: 200px;
}

#weeklyTable tbody tr {
    background-color: white !important;
}
