/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container{
    height:100%;
    max-width:100% !important;
    margin-left:0px;
    margin-right:15px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.formContainer {
    overflow: auto;
    width: 100%;
    height: calc(100dvh - 70px);
}

.form {
    margin-top:5px !important;
    padding: 15px 15px 0px 15px;
    max-width: 1000px;
    width: 90%;
    margin: auto;
    box-shadow:rgb(195, 183, 183) 0px 0px 5px;
    background-color:white;
    overflow-x:auto;
}

head {
    background: #f3f3f3;
}

body{
    overflow:hidden;
}

header{
    background-color:white;
}
