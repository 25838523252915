.ui.buttons .button {
    background-color: transparent;
    font-size: 12px !important;
    width: 100px;
    padding: 0px;
}

.confidencePointDropdown{
    font-size:14px;
    min-width: 85px;
}

.ui.buttons .button:disabled{
    opacity: .6 !important;
}

/*.ui.buttons .or {
    z-index:unset !important
}*/
.ui.buttons .button:hover {
    background-color: transparent;
}

.ui.buttons .button[winner='set'] {
    background-color: #add8e6;
    border: solid !important;
    border-color: #00000040 !important;
}
.ui.buttons .button[winner='unset'] {
    background-color: transparent;
    border: solid !important;
    border-color: transparent !important;
}
.ui.buttons .button[winner='true'] {
    background-color: #99ba9f;
}
.ui.buttons .button[winner='false'] {
    background-color: rgb(203 56 56 / 1);
}

.ui.buttons .or:before {
    content: '@' !important;
}

.buttonLabel
{
    cursor:pointer;
    margin-bottom: 0px;
}

.emptyOption{
    background-color:red
}

.spreadLabel{
    font-size:14px;
}

.longDateLabel{
    font-size: 14px;
    width: 100px;
}

.shortDateLabel{
    font-size: 14px;
    padding-right: 0px;
}

.iconLabel{
    width: 20px;
}

#allWeekNo{
    font-size:16px;
}

#myPicksTable tr td, tr th {
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align:middle;
}

@media (max-width:600px){
    .ui.buttons .button {
        width: 75px;
    }
}

@media (max-width:600px){
    .spreadLabel{
        display:none;
    }
    .buttonLabel{
        display:none;
    }
}

@media (max-width:500px) {
    .confidencePointPicker {
        width: 25px !important;
    }
    .shortDateLabel{
        display:table-cell !important;
    }
    .iconLabel {
        padding-left: 0px !important;
    }
    .longDateLabel{
        display:none;
    }

    .MyPicks{
        margin:0px;
        margin-top:-15px !important;
        width:100% !important;
        height: 100% !important;
        box-shadow:none !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.dropdown-select__control.dropdown-select__control--is-focused {
    font-size: 16px !important;
}