#playoffTable {
    table-layout: auto;
    width: 100%;
    height: 200px;
    border-collapse: collapse;
    font-size: 14px;
}

    #playoffTable th, .table td {
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0px;
    }

    #playoffTable tr td {
        width: 100%;
    }

    #playoffTable th {
        overflow: hidden;
        width: 50px;
        padding: 5px;
    }

    #playoffTable td {
        overflow: hidden;
        width: 50px;
        padding: 5px;
    }

    #playoffTable tr td, tr th {
        white-space: nowrap;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: unset;
    }