#overallTable {
    table-layout: auto;
    width: 100%;
    height: 200px;
    border-collapse: collapse;
    font-size: 14px;
}

#overallTable th, .table td {
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
}

#overallTable tr td {
    width: 100%;
}

#overallTable th {
    overflow: hidden;
    width: 50px;
    padding: 5px;
}

#overallTable td {
    overflow: hidden;
    width: 50px;
    padding: 5px;
}

#overallTable tr td, tr th {
    white-space: nowrap;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: unset;
}

#a {
    height: 100%;
}
