form {
    background: #727882;
    padding: 1.5em;
    border-radius: 1em;
    color: white;
    min-width: 300px;

}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    margin-top: 1em;
    color: red;
}
